import React, { Component } from 'react'
import '../css/menu.css'
class MenuVelo extends Component{

  render(){
    return(
      <div className="container-menu">
      <div className="menu">
        <div className="menu-text">
          <h1>Vélo'<span> Taff</span></h1>
          <h2><span>GRAND</span>LYON</h2>
          <h3>La métropole</h3>
        </div>
        </div>
      </div>
    )
  }
}
export default MenuVelo